<template>
  <div>
    <Nav />
    <div v-if="success" class="forget-body">
      <div class="forget-form">
        <div class="forget-top">
          <img src="../../assets/02.png" alt="">
          <div>重置密码</div>
        </div>
        <div class="forget-bottom">
          <div class="forget-phone">
            <div class="title-name">新密码</div>
            <input type="text" class="phone-forget" placeholder="请输入新密码">
          </div>
          <div class="forget-number">
            <div class="title-name">确认密码</div>
            <input type="text" class="number-forget"  placeholder="请输入密码">
          </div>
          <div class="nextbtn" @click="update()">提交</div>
        </div>
      </div>
    </div>
    <div v-else  class="forget-body">
      <div class="forget-form">
        <div class="text">您的密码已经修改成功</div>
        <div class="ljlogin" @click="toLogin()">立即登录</div>
      </div>
    </div>
    <Footer />
 </div>
</template>

<script>
import Nav from '@/views/nav/Nav.vue'
import Footer from '@/views/footer/Footer.vue'
import './forget.css'
export default {
  name: '',
  data () {
    return {
      success: true,
    }
  },
  props: {},
  components: {
    Nav,
    Footer,
  },
  mounted () {},
  methods: {
    update() {
      this.success = !this.success
    },
    toLogin() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped lang='less'>
</style>
